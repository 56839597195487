export const cmvReport = {
	label: "CMV",
	description:
		"O Custo de Mercadoria Vendida (CMV) é o custo total incorrido para adquirir mercadorias que foram vendidas durante um determinado período de tempo. Ele é essencial para determinar o lucro bruto de uma empresa.",
	export: "Download em Excel",
	periodInfo: "Exibindo relatório de {{since}} até {{until}}",
	columns: {
		product: "Produto",
		category: "Categoria",
		quantity: "Quantidade",
		fiscalCode: "SKU",
		ncm: "NCM",
		count: "Quantidade",
		unitValue: "Valor unitário",
		totalValue: "Valor total",
		productUnitCost: "Custo unitário",
		totalCost: "Custo Total",
		percentageCost: "Custo percentual",
		margin: "Margem",
		empty: "Nenhum produto encontrado!",
		total: "Total",
		totalProduct: "Total ({{product}})",
	},
	forms: {
		cmvType: "Produtos exibidos",
		cmvType1: "Todos os produtos",
		cmvType2: "Apenas produtos do place",
		cmvType3: "Apenas produtos do vendor",
		search: "Categoria",
		searchPlaceholder: "Busque por categoria",
		period: "Período",
		product: "Produto",
		productPlaceholder: "Busque por produto",
	},
	infoCards: {
		updated: "Atualizado",
		titleCard1: "Quantidade de produtos",
		titleCard2: "Valor total",
		titleCard3: "Custo total",
		titleCard4: "Margem percentual",
		titleCard5: "Margem",
		titleCard6: "Custo unitário zerado",
		titleCard6Action: "Ver todos",
	},
	sheet: {
		title: "Relatório de CMV",
		fileName: "relatorio_cmv",
		product: "Produto",
		fiscalCode: "SKU",
		ncm: "NCM",
		count: "Quantidade",
		unitValue: "Valor unitário",
		totalValue: "Valor total",
		productUnitCost: "Custo unitário",
		totalCost: "Custo Total",
		margin: "Margem",
	},
	expandIcon: {
		arrowUp: "Ver produtos",
		arrowDown: "Fechar produtos",
	},
	expandedTable: {
		loading: "Carregando",
		columns: {
			product: "Produto",
			codeProduct: "SKU",
			quantity: "Quantidade",
			lostQuantity: "Perdas",
			unitValue: "Valor unitário",
			totalValue: "Valor total",
			unitCost: "Custo unitário",
			totalCost: "Custo total",
			percentMargin: "Margem percentual",
			cmv: "CMV",
			margin: "Margem",
			supplie: "Insumo",
		},
	},
};
